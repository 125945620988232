<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="设备名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入设备名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="设备型号" prop="model">
                <a-input v-model="queryParam.model" placeholder="请输入设备型号" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="市名称" prop="cityName">
                  <a-input v-model="queryParam.cityName" placeholder="请输入市名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="市编码" prop="cityCode">
                  <a-input v-model="queryParam.cityCode" placeholder="请输入市编码" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="区名称" prop="areaName">
                  <a-input v-model="queryParam.areaName" placeholder="请输入区名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="区编码" prop="areaCode">
                  <a-input v-model="queryParam.areaCode" placeholder="请输入区编码" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="二维码" prop="qrCode">
                  <a-input v-model="queryParam.qrCode" placeholder="请输入设备二维码地址" allow-clear/>
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item label="设备编号" prop="number">
                  <a-input v-model="queryParam.number" placeholder="请输入设备编号" allow-clear/>
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item label="使用地点" prop="address">
                  <a-input v-model="queryParam.address" placeholder="请输入使用地点" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="操作员姓名" prop="operatorName">
                  <a-input v-model="queryParam.operatorName" placeholder="请输入操作员姓名" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="操作员编号" prop="operatorNum">
                  <a-input v-model="queryParam.operatorNum" placeholder="请输入操作员编号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="联系电话" prop="phone">
                  <a-input v-model="queryParam.phone" placeholder="请输入联系电话" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="使用登记证" prop="registration">
                  <a-input v-model="queryParam.registration" placeholder="请输入使用登记证" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="使用标志" prop="sign">
                  <a-input v-model="queryParam.sign" placeholder="请输入使用标志" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="验收记录" prop="acceptanceRecords">
                  <a-input v-model="queryParam.acceptanceRecords" placeholder="请输入验收记录" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="操作手证件" prop="operatorDocument">
                  <a-input v-model="queryParam.operatorDocument" placeholder="请输入操作手证件" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['device:device:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['device:device:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['device:device:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['device:device:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="qrCode" slot-scope="text, record">
           <img v-viewer  style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.qrCode"
                :src="record.qrCode" preview="图片"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['device:device:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['device:device:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['device:device:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['device:device:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDevice,listDevice, delDevice } from '@/api/device/device'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Device',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        provinceName: null,
        provinceCode: null,
        cityName: null,
        cityCode: null,
        areaName: null,
        areaCode: null,
        qrCode: null,
        imgs: null,
        name: null,
        number: null,
        model: null,
        address: null,
        nextInspectionTime: null,
        operatorName: null,
        operatorNum: null,
        phone: null,
        registration: null,
        sign: null,
        acceptanceRecords: null,
        operatorDocument: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: '设备id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '省',
          dataIndex: 'provinceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '市',
          dataIndex: 'cityName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '区',
          dataIndex: 'areaName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '二维码',
          dataIndex: 'qrCode',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'qrCode'}
        },
        /*{
          title: '设备图片,多个使用逗号隔开',
          dataIndex: 'imgs',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '设备名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '编号',
          dataIndex: 'number',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '型号',
          dataIndex: 'model',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '使用地点',
          dataIndex: 'address',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '下次检验日期',
          dataIndex: 'nextInspectionTime',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作员',
          dataIndex: 'operatorName',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '操作员编号',
          dataIndex: 'operatorNum',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '电话',
          dataIndex: 'phone',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '使用登记证',
          dataIndex: 'registration',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '使用标志',
          dataIndex: 'sign',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '验收记录',
          dataIndex: 'acceptanceRecords',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '操作手证件',
          dataIndex: 'operatorDocument',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: '8%'
        },
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '13%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询设备列表 */
    getList () {
      this.loading = true
     pageDevice(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        provinceName: undefined,
        provinceCode: undefined,
        cityName: undefined,
        cityCode: undefined,
        areaName: undefined,
        areaCode: undefined,
        qrCode: undefined,
        imgs: undefined,
        name: undefined,
        number: undefined,
        model: undefined,
        address: undefined,
        nextInspectionTime: undefined,
        operatorName: undefined,
        operatorNum: undefined,
        phone: undefined,
        registration: undefined,
        sign: undefined,
        acceptanceRecords: undefined,
        operatorDocument: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delDevice(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('device/device/export', {
            ...that.queryParam
          }, `设备_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
